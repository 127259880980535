import axios from 'axios'
import userUtils from '@/utils/UserUtils'
import store from '@/store'
import router from '@/router'

let baseURL;
if (window.location.toString().indexOf('localhost') >= 0) {
  baseURL = 'http://localhost/khula-api';
} else {
  baseURL = 'https://api-legacy.khula.online';
}

const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: baseURL,
    headers: {
        'content-type': 'application/json'
    },    
});

axiosInstance.interceptors.request.use(function(config) {
    const token = userUtils.getToken();
    if (token != '') {
      config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
}, function(err) {
    return Promise.reject(err);
});

axiosInstance.interceptors.response.use(
    response => {

        if (response.data.success === false) {
            // JWT expired. or Invalid remote IP.
            if ( (response.data.error.code === 9) || (response.data.error.code === 7) || (response.data.error.code === 11) ) { 
                store.commit('signOut'); 
                router.push("/").catch(()=>{});                
            }             
        }

        return Promise.resolve(response);

      //if (response.status === 200 || response.status === 201) {
      //  return Promise.resolve(response);
      //} else {
      //  return Promise.reject(response);
      //}
    },
  /*error => {
      if (error.response.status) {
        switch (error.response.status) {
          case 400:
           
           //do something
            break;
        
          case 401:
            alert("session expired");
            break;
          case 403:
            router.replace({
              path: "/login",
              query: { redirect: router.currentRoute.fullPath }
            });
             break;
          case 404:
            alert('page not exist');
            break;
          case 502:
           setTimeout(() => {
              router.replace({
                path: "/login",
                query: {
                  redirect: router.currentRoute.fullPath
                }
              });
            }, 1000);
        }
        return Promise.reject(error.response);
      }
    }*/
  );

export default axiosInstance;
